/*******************************************
 * 描述：工具函数库
 * @param {}
 *******************************************/
import { regionData, CodeToText } from "element-china-area-data";
// import CodeToLatLng from "@/assets/json/CodeToLatLng.json";
import moment from "moment";

/*******************************************
 * 描述：递归遍历树将其扁平化--返回一个对象，通过id索引可拿到树上的任何一个对象
 * @param {treeArray} Array<object> 树数据对象
 * @param {childName} string 子级索引名称
 * @param {key} string 键名
 *******************************************/
const flatTree = (treeArray, childName, key = "id") => {
  const hashTree = {};
  function getTreeItem(arr, pid) {
    if (!arr) return;
    arr.forEach((item) => {
      if (item && item[key]) {
        hashTree[item[key] + ""] = item;
        if (pid) item.parentId = pid;
        if (item[childName] && item[childName].length) {
          getTreeItem(item[childName], item[key]);
        }
      }
    });
  }
  getTreeItem(treeArray);
  return hashTree;
};
/*******************************************
 * 描述：深拷贝
 * @param {}
 *******************************************/
const deepClone = (target) => {
  // 定义一个变量
  let result;
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === "object") {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      result = []; // 将result赋值为一个数组，并且执行遍历
      for (let i in target) {
        // 递归克隆数组中的每一项
        result.push(deepClone(target[i]));
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if (target === null) {
      result = null;
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if (target.constructor === RegExp) {
      result = target;
    } else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      result = {};
      for (let i in target) {
        result[i] = deepClone(target[i]);
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target;
  }
  // 返回最终结果
  return result;
};

/*******************************************
 * 描述：省-市
 * @param {}
 *******************************************/
const regionData_city = deepClone(regionData).map((item) => {
  if (item.children) {
    item.children.forEach((child) => {
      delete child.children;
    });
  }
  return item;
});
/*******************************************
 * 描述：把一个对象的所有属性值赋给另一个对象
 * @param {from} //
 * @param {to} //
 *******************************************/
const setObjectValues = (obj_from, obj_to) => {
  if (obj_from && obj_to) {
    Object.keys(obj_from).forEach((key) => {
      if (obj_to[key]) obj_to[key] = null;
      obj_to[key] = obj_from[key];
    });
  }
};

const flatRegionData = flatTree(regionData, "children", "value");

/*******************************************
 * 描述：根据区id 返回 [省 市 区]id
 * @param {scode}
 *******************************************/
const getRegionParents = (code) => {
  let arr = [code];
  if (flatRegionData[code] && flatRegionData[code].parentId) {
    arr = getRegionParents(flatRegionData[code].parentId).concat(arr);
  }
  return arr;
};
/*******************************************
 * 描述：导出到excel表格
 * @param {res} //
 * @param {name} //
 *******************************************/
const expXlsx = (res, name) => {
  const blob = new Blob([res], { type: "application/vnd.ms-excel" });
  const fileName = name + " " + moment().format("YYYY-MM-DD") + ".xlsx"; //文件名
  const linkNode = document.createElement("a");
  linkNode.download = fileName; //a标签的download属性规定下载文件的名称
  linkNode.style.display = "none";
  linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
  document.body.appendChild(linkNode);
  linkNode.click(); //模拟在按钮上的一次鼠标单击
  URL.revokeObjectURL(linkNode.href); // 释放URL 对象
  document.body.removeChild(linkNode);
};

/*******************************************
 * 描述：拼接get请求字符串
 * @param {data} object
 *******************************************/
const queryString = (data) => {
  let str = "";
  if (!data) return str;
  Object.keys(data).forEach((key) => {
    str += `&${key}=${data[key]}`;
  });
  return "?" + str.substring(1);
};

const mingzu = [
  "汉族",
  "壮族",
  "回族",
  "满族",
  "维吾尔族",
  "苗族",
  "彝族",
  "土家族",
  "藏族",
  "蒙古族",
  "侗族",
  "布依族",
  "瑶族",
  "白族",
  "朝鲜族",
  "哈尼族",
  "黎族",
  "哈萨克族",
  "傣族",
  "畲族",
  "傈僳族",
  "东乡族",
  "仡佬族",
  "拉祜族",
  "佤族",
  "水族",
  "纳西族",
  "羌族",
  "土族",
  "仫佬族",
  "锡伯族",
  "柯尔克孜族",
  "景颇族",
  "达斡尔族",
  "撒拉族",
  "布朗族",
  "毛南族",
  "塔吉克族",
  "普米族",
  "阿昌族",
  "怒族",
  "鄂温克族",
  "京族",
  "基诺族",
  "德昂族",
  "保安族",
  "俄罗斯族",
  "裕固族",
  "乌孜别克族",
  "门巴族",
  "鄂伦春族",
  "独龙族",
  "赫哲族",
  "高山族",
  "珞巴族",
  "塔塔尔族",
];
export {
  flatTree,
  deepClone,
  regionData,
  regionData_city,
  CodeToText,
  // CodeToLatLng,
  setObjectValues,
  getRegionParents,
  expXlsx,
  mingzu,
  queryString,
};
